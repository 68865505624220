<template>
  <UModal>
    <UCard :ui="{ ring: '', divide: 'divide-y divide-gray-100 dark:divide-gray-800' }">
      <h2 class="text-lg font-semibold text-gray-900 mb-4 max-w-full truncate">
        <template v-if="type===ArchiveModalType.MODULE">
          Archive module "{{ module?.name }}"
        </template>
        <template v-if="type===ArchiveModalType.MODULES">
          Archive Modules
        </template>
        <template v-if="type===ArchiveModalType.SECTION">
          Archive section "{{ section?.name }}"
        </template>
      </h2>

      <p v-if="type===ArchiveModalType.MODULE" class="text-sm">
        Archiving this module will archive its {{ subtasks.length }} children tasks. Unlink to keep children tasks unarchived.
      </p>
      <p v-if="type===ArchiveModalType.SECTION && subtasks && subtasks.length" class="text-sm">
        This section includes module tasks; archiving will archive all children tasks. Unlink to keep them unarchived.
      </p>
      <p v-if="type===ArchiveModalType.MODULES" class="text-sm">
        Archiving these modules will archive all children tasks. Unlink to keep them unarchived.
      </p>
      <div class="flex items-center gap-2 mt-2">
        <UCheckbox v-model="unlinkChildren" label="Unlink the parent task to keep children tasks unarchived" />
      </div>

      <div class="flex justify-end items-center gap-2 mt-8">
        <UButton color="gray" variant="ghost" @click="() => onCancel()">
          Cancel
        </UButton>
        <UButton v-if="type===ArchiveModalType.MODULE" color="red" @click="() => onArchiveModule()">
          Archive module
        </UButton>
        <UButton v-if="type===ArchiveModalType.SECTION" color="red" @click="() => onArchiveSection()">
          Archive section
        </UButton>
        <UButton v-if="type === ArchiveModalType.MODULES" color="red" @click="() => onArchiveModules()">
          Archive module
        </UButton>
      </div>
    </UCard>
  </UModal>
</template>

<script setup lang="ts">
import type { ArchivingTaskType } from '#task/types'
import { ArchiveModalType, TaskLevel } from '#task/constant'
import type { SectionItem } from '#section/types'

const props = defineProps({
  module: {
    type: Object as PropType<ArchivingTaskType>,
    required: false,
  },
  modules: {
    type: Object as PropType<Array<ArchivingTaskType>>,
    required: false,
  },
  archiveTasks: {
    type: Object as PropType<Array<ArchivingTaskType>>,
    required: false,
  },
  section: {
    type: Object as PropType<SectionItem>,
    required: false,
  },
  type: {
    type: String,
    default: ArchiveModalType.MODULE,
  },
  onCancel: {
    type: Function,
    default: () => {},
  },
})

const toast = useToast()
const { updateTask, updateTasks } = useUpdateTask()
const { updateSection } = useUpdateSection()

// Allowed to use all tasks filter as we process both subtasks and section tasks
const { tasks } = useBoardTasksLoader()

const subtasks = computed(() => {
  return tasks.value.filter(task => {
    if (props.type === ArchiveModalType.MODULE) {
      return task.parentId === props.module?.id
    } else {
      return task.sectionId === props.section?.id && task.level === TaskLevel.MODULE && task.subtasksCount
    }
  })
})

const unlinkChildren = ref(false)

const onArchiveModule = async () => {
  props.onCancel()
  if (!props.module) {
    toast.add({
      icon: 'i-heroicons-exclamation',
      color: 'red',
      title: 'Error',
      description: 'Module not found',
    })
    return
  }

  await updateTask(props.module.id, { closed: true, unlinkChildren: unlinkChildren.value })
  const actions = [
    {
      label: 'Undo',
      click: () => {
        if (!props.module) return

        updateTask(props.module.id, { closed: false })
      },
    },
  ]
  toast.add({
    icon: 'i-heroicons-check-circle',
    color: 'green',
    title: 'Task archived',
    actions,
  })
}

const onArchiveModules = async () => {
  props.onCancel()
  if (!props.modules || !props.archiveTasks) {
    toast.add({
      icon: 'i-heroicons-exclamation',
      color: 'red',
      title: 'Error',
      description: 'Modules not found',
    })
    return
  }

  const isModule = (task: ArchivingTaskType) => task.level === TaskLevel.MODULE && task.subtasksCount
  const nonModuleTasks = props.archiveTasks.filter((task: ArchivingTaskType) => !isModule(task) && !task.parentId)

  await updateTasks(props.modules.map(task => task.id), { closed: true, unlinkChildren: unlinkChildren.value })
  if (nonModuleTasks.length) {
    await updateTasks(nonModuleTasks.map((task: ArchivingTaskType) => task.id), { closed: true })
  }

  const actions = [
    {
      label: 'Undo',
      click: () => {
        if (!props.module) return

        updateTask(props.module.id, { closed: false })
      },
    },
  ]
  toast.add({
    icon: 'i-heroicons-check-circle',
    color: 'green',
    title: 'Task archived',
    actions,
  })
}

const onArchiveSection = async () => {
  props.onCancel()
  if (!props.section) {
    toast.add({
      icon: 'i-heroicons-exclamation',
      color: 'red',
      title: 'Error',
      description: 'Section not found',
    })
    return
  }

  await updateSection(props.section.id, { closed: true, unlinkChildren: unlinkChildren.value })
  const actions = [
    {
      label: 'Undo',
      click: () => {
        if (!props.section) return

        updateSection(props.section.id, { closed: false })
      },
    },
  ]
  toast.add({
    icon: 'i-heroicons-check-circle',
    color: 'green',
    title: 'Section archived',
    actions,
  })
}
</script>
